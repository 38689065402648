<template>
  <div class="col">
    <div class="card">
      <div class="card-body placeholder-glow">
        <h5 class="card-title">Lämpötilat</h5>
        <div class="card-text">
          <div :class="{ placeholder: !log.datetime }">
            <span :class="{ 'bi-thermometer-sun': itswarm, 'bi-thermometer-snow': !itswarm }" />
            Lämpötila:
            <span v-if="log">{{ log.ta60savg }}</span>
            <span v-if="!log.datetime">0.0</span>
            &deg;C
          </div>
          <div :class="{ placeholder: !log.datetime }">
            <span class="bi-moisture" /> Kastepiste:
            <span v-if="log">{{ log.dp60savg }}</span>
            <span v-if="!log.datetime">0.0</span>
            &deg;C
          </div>
          <div :class="{ placeholder: !log.datetime }">
            <span class="bi-wind" /><span v-if="log.ta60savg >= log.ha60savg"> Tuulen hyytävyys: </span><span v-else> Tuuli tuntuu kuin: </span>
            <span v-if="log">{{ log.wc60savg }}</span>
            <span v-if="!log.datetime">0.0</span>
            &deg;C
          </div>
          <div :class="{ placeholder: !log.datetime }">
            <span class="bi-activity" v-if="log.wbgt60sapx > 0"/><span v-if="log.wbgt60sapx > 0"> Arvioitu <a href="https://en.wikipedia.org/wiki/Wet-bulb_globe_temperature" target="_new">WBGT</a>:
              <span v-if="log">{{ log.wbgt60sapx }}</span>
              <span v-if="!log.datetime">0.0</span>
              &deg;C
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemperaturesCard",
  props: [
    'log'
  ],
  computed: {
    itswarm: function () {
      if (this.log.ta60savg > 4) {
        return true
      }
      return false
    },
  }
}
</script>

<style scoped>
</style>
